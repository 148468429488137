import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import styled, { keyframes, createGlobalStyle } from 'styled-components'
import SEO from "../components/seo"
import linkedIcon from '../icons/In-Blue-128@2x.png'
import linkedIconWhite from '../icons/In-White-128@2x.png'
import "../components/layout.css"

const GlobalStyle = createGlobalStyle`
  html{visibility: visible;opacity:1;}
`

const Wrapper = styled.aside`
  font-family: 'Museosans 100', sans-serif;
  display: grid;
  grid-gap: 5px;
  grid-template-areas: "left-column";
  @media only screen and (max-width: 600px) {
    grid-template-areas: "left-column";
  }
  color: #4a4a4a;
`
const LeftColumn = styled.aside`
  grid-area: left-column;
  padding: 75px 85px 50px;
  background-color: #fafafa;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fafafa), to(#fafafa));
  background-image: linear-gradient(180deg, #fafafa, #fafafa);
  height: 100vh;
`

const RightColumn = styled.aside`
  grid-area: right-column;
  height: auto;
  padding: 45px 65px;
  background-color: #4a4a4a;
  display:none;
`

const keyFrameFadeIn = keyframes`
  0% {
    filter: invert(100%);
    background-image: url(${linkedIconWhite});
  }
  100% {
    background-image: url(${linkedIcon});
  }
`

const keyFrameFade = keyframes`
  0% {
    background-image: url(${linkedIcon});
  }
  100% {
    filter: invert(100%);
    background-image: url(${linkedIconWhite});
  }
`

const keyFrameIntro = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20px) translateY(0px) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) translateY(0px) translateZ(0px);
  }
`

const animateDiv = styled.div`
  opacity: 0;
  animation: ${keyFrameIntro};
  animation-timing-function: ease;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
`

const Intro = styled(animateDiv)`
  text-transform: uppercase;
  animation-delay: 500ms;
`

const Desc = styled(animateDiv)`
  animation-delay: 1200ms;  
  font-size: 16px;
  line-height: 26px;
  color: #333;
  max-width:500px;
`

const Media = styled(animateDiv)`
  animation-delay: 1500ms; 
`
const MediaLink = styled.a`
  cursor: pointer;
  &:hover {
    img {
      filter: invert(0%);
      animation: ${keyFrameFadeIn};
      animation-timing-function: ease;
      animation-duration: 1000ms;
      animation-fill-mode: forwards;
    }
  }

  img {
    filter: invert(0%);
    background-image: url(${linkedIcon});
    animation: ${keyFrameFade};
    animation-timing-function: ease;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
    animation-delay: 2000ms; 

    width: 36px;
    height: 36px;
    border-radius: 6px;
    background-size: 36px;
    background-repeat: no-repeat;
  }
`


const IndexPage = () => (
  <Wrapper>
    {process.env.NODE_ENV !== 'development' &&
      <Helmet>
        <style type="text/css">{`
      html{visibility: hidden;opacity:0;}
    `}
        </style>
      </Helmet>
    }
    <SEO title="Martin McCarthy Web Development" />
    <LeftColumn>
      <Intro>
        <h1><div>Web Developer.</div>
          <div>Consultant.</div>
          <div>Tech Enthusiast.</div>
        </h1>
      </Intro>
      <Desc>
        <p>Some of the companies I have previously worked at include <strong>Budbee</strong>, <strong>Qliro</strong>, <strong>Doktor.se</strong></p>
      </Desc>
      <Media>
        <MediaLink href="https://se.linkedin.com/in/martin-mccarthy-stockholm" target="_blank" alt="Martin McCarthy" url={linkedIcon} >
          <img />
        </MediaLink>
      </Media>
    </LeftColumn>
    <RightColumn>

    </RightColumn>
    <Fragment><GlobalStyle /></Fragment>
  </Wrapper>
)

export default IndexPage
